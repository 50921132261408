const notifications = ref([]);


export default function useNotifications() {

    const push = (message, type = 'info', duration = 5000, detail: string|null = null) => {

        let classes = ""
        let icon = ""
        switch (type) {
            case 'info':
                classes = "bg-primary"
                icon = "fal fa-circle-info"
                break;
            case 'warning':
                classes = "bg-orange-500"
                icon = "fal fa-triangle-exclamation"
                break;
            case 'success':
                classes = "bg-green-500"
                icon = "fal fa-circle-check"

                break;
            case 'danger':
                classes = "bg-red-500"
                icon = "fal fa-circle-xmark"

                break;
        }

        const notification = {
            id: Date.now(),
            message,
            detail: detail,
            type,
            classes: classes,
            icon: icon
        };

        notifications.value.push(notification);

        setTimeout(() => {
            remove(notification.id);
        }, duration);
    };

    const remove = (id) => {
        const index = notifications.value.findIndex((n) => n.id === id);

        if (index !== -1) {
            notifications.value.splice(index, 1);
        }
    };

    return {
        notifications,
        push,
        remove,
    };
}